
    import ContentRenderer from "../components/ContentRenderer";
	
    export default {
        components: {ContentRenderer},
        name: 'Pages',
        async asyncData(ctx) {
            const { data } = await ctx.$axios('/page')
            const findPage = data.docs.find( e => e.url === '/')
            const sections = findPage?.sections
            const title = findPage?.metaFields?.metaTitle
            const description = findPage?.metaFields?.metaDescription
            const ogUrl = findPage?.metaFields?.ogImage?.url
            return {sections, title, description, ogUrl}
        },
        data () {
            return {
                sections: [],
                title: '',
                description: '',
                ogUrl: ''
            }
        },
        head () {
            return {
                title: this.title,
                meta: [{
                    hid: 'description',
                    name: 'description',
                    content: this.description
                },
                    {
                        hid: 'og:image',
                        property: 'og:image',
                        content: this.ogUrl
                    }]
            }
        },
        methods: {
        }
    }
