
export default {
  name: 'UiSection',
  props: {
    decorLeft: {
      type: String,
      default: null
    },
    decorLeftPlacement: {
      type: String,
      default: 'top' // bottom, center
    },
    decorRight: {
      type: String,
      default: null
    },
    decorRightPlacement: {
      type: String,
      default: 'top' // bottom, center
    },
    marginTop: {
      type: String,
      default: 'l' // s, m
    },
    containerSize: {
      type: String,
      default: 'm' // s, full-width
    },
    overflowHidden: {
      type: Boolean,
      default: false
    }
  }
}
