
import {richTextConstructor} from "../helpers";

export default {
  name: 'SectionHeading',
  props: {
    blockOptions: {
      type: Object
    }
  },
  computed: {
    contentHTML(){
      if(this.$props.blockOptions.richText.content){
        return richTextConstructor(this.$props.blockOptions.richText.content)
      }else {
        return ''
      }
    }
  }
}
