
export default {
  name: "ContentRenderer",
  props: {
    sections: {
      type: Array,
    },
  },
  methods: {
    blockType(type) {
      switch (type) {
        case "IntroMain":
          return "VideoBlock";
        case "Card":
          return "RectangleBgCardBlock";
        case "Carousel":
          return "ThumbsSlider";
        case "CarouselIcon":
          return "MarqueeSlider";
        case "CurvesCard":
          return "ShapeBgCardBlock";
        case "ListCard":
          return "PlansList";
        case "ContainerBG":
          return "CtaSection";
        case "Accordion":
          return "FaqList";
        case "richBlock":
          return "RichBlock";
      }
    },
  },
  mounted() {},
};
